import { getImage as gatsbyGetImage } from 'gatsby-plugin-image'
import { useState, useEffect } from 'react'
import csv from 'csvtojson'

export const getImage = image => gatsbyGetImage(image.localFile)

export const getDate = dateString => {
  const parts = dateString.split('/')
  return new Date(parts[2], parts[1], parts[0])
}

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(' ')
}

/**
 * Accepts a media query string then uses the
 * [window.matchMedia](https://developer.mozilla.org/en-US/docs/Web/API/Window/matchMedia) API to determine if it
 * matches with the current document.<br />
 * It also monitor the document changes to detect when it matches or stops matching the media query.<br />
 * Returns the validity state of the given media query.
 *
 */
/* eslint-disable */

export const useMediaQuery = mediaQuery => {
  if (typeof window === 'undefined') {
    console.warn('cannot server side render useMediaQuery')
    return null
  }
  const [isVerified, setIsVerified] = useState(
    !!window.matchMedia(mediaQuery).matches
  )

  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQuery)
    const documentChangeHandler = () => setIsVerified(!!mediaQueryList.matches)

    try {
      mediaQueryList.addEventListener('change', documentChangeHandler)
    } catch (e) {
      // Safari isn't supporting mediaQueryList.addEventListener
      mediaQueryList.addListener(documentChangeHandler)
    }

    documentChangeHandler()
    return () => {
      try {
        mediaQueryList.removeEventListener('change', documentChangeHandler)
      } catch (e) {
        // Safari isn't supporting mediaQueryList.removeEventListener
        mediaQueryList.removeListener(documentChangeHandler)
      }
    }
  }, [mediaQuery])

  return isVerified
}
/* eslint-enable */

export const fetchCSVData = colParser => url => {
  if (typeof colParser === 'undefined') {
    colParser = {}
  }
  return fetch(url)
    .then(res => res.text())
    .then(csvString => {
      return csv({
        colParser,
      })
        .fromString(csvString)
        .then(
          json => {
            return json
          },
          err => {
            console.error('Failed to parse csv')
            console.error(err)
          }
        )
    })
    .catch(err => {
      console.error('Failed to load csv')
      console.error(err)
    })
}

export const getLinkProps = menuItemNode => {
  if (menuItemNode.path === '#country-snapshots') {
    return {
      name: menuItemNode.label,
      to: '/',
      state: { skipToCountries: true },
    }
  } else {
    return {
      name: menuItemNode.label,
      to: menuItemNode.path,
    }
  }
}

export const convertToSlug = text => {
  return text
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '')
}

export const stripTags = html => {
  if (typeof window !== 'undefined') {
    const div = document.createElement('div')
    div.innerHTML = html
    return div.innerText
  }
  return html
}
