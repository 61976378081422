/* eslint react/jsx-no-target-blank: 0 */
import React, { Fragment, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { Menu, Transition, Dialog } from '@headlessui/react'
import Logo from '../../../assets/images/svgs/logo.svg'
import TwitterIcon from '../../../assets/images/svgs/twitter-solid.svg'
import { MenuIcon, XIcon, ChevronDownIcon } from '@heroicons/react/outline'
import arrayToTree from '../../../utils/array-to-tree'
import { classNames, getLinkProps } from '../../../utils/misc'

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderMenu {
      wpMenu(locations: { eq: HEADER }) {
        menuItems {
          nodes {
            id
            label
            path
            parentId
          }
        }
      }
      wp {
        acfOptionsSiteConfiguration {
          siteConfiguration {
            twitter
          }
        }
      }
    }
  `)
  const rawNavData = data.wpMenu.menuItems.nodes
  const navData = arrayToTree(rawNavData, getLinkProps)

  const [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }
  return (
    <header>
      <div className="relative z-20 bg-blue-600">
        <div className="container py-2">
          <nav
            className="relative flex items-center text-white"
            aria-label="Global"
          >
            <Link to="/" className="text-xl font-bold">
              <span className="sr-only">Home page</span>
              <Logo className="w-12 sm:w-auto" />
            </Link>
            <div className="ml-auto flex items-center">
              <DesktopMenu navData={navData} />
              <button
                className="sm:hidden font-medium text-white focus-white"
                onClick={openModal}
              >
                <span className="sr-only">Open main menu</span>
                <MenuIcon className="h-8 w-8" aria-hidden="true" />
              </button>
            </div>
          </nav>
          <MobileMenu
            show={isOpen}
            closeModal={closeModal}
            navData={navData}
            siteConfig={data.wp.acfOptionsSiteConfiguration.siteConfiguration}
          />
        </div>
      </div>
    </header>
  )
}

const DesktopMenu = ({ navData }) => {
  const buttonClass = 'font-medium hover:text-blue-50 p-2'
  return (
    <div className="hidden sm:flex ml-10 space-x-8">
      {navData.map(item =>
        item.children ? (
          <Menu as="div" key={item.name} className="relative">
            <Menu.Button
              className={`${buttonClass} flex items-center space-x-2`}
            >
              <span>{item.name}</span>
              <ChevronDownIcon className="h-5 w-5" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Menu.Items className="absolute transform z-20 w-screen max-w-[250px] left-auto right-0 2xl:left-1/2 2xl:-translate-x-1/2 2xl:right-auto">
                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden bg-white py-3">
                  {item.children.map(childItem => (
                    <Menu.Item key={childItem.name}>
                      <DesktopMenuSubMenuItem item={childItem} />
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        ) : (
          <Link
            key={item.name}
            to={item.to}
            state={item.state}
            className={buttonClass}
          >
            {item.name}
          </Link>
        )
      )}
    </div>
  )
}

const DesktopMenuSubMenuItem = ({ item, depth = 0 }) => {
  let pl, weight
  if (depth === 0) {
    pl = 'pl-5'
    weight = 'font-medium'
  } else if (depth === 1) {
    pl = 'pl-8'
  } else if (depth > 1) {
    pl = 'pl-11'
  }
  return (
    <>
      <Link
        key={item.name}
        to={item.to}
        className={`block ${pl} pr-5 py-2 ${weight} text-blue-600 hover:text-white hover:bg-blue-600`}
      >
        <span>{item.name}</span>
      </Link>
      {item.children &&
        item.children.map(childItem => (
          <DesktopMenuSubMenuItem
            key={childItem.name}
            item={childItem}
            depth={depth + 1}
          />
        ))}
    </>
  )
}

const mobileNavItemClass = 'block px-2 py-3 rounded-md text-base text-blue-600'

const MobileMenu = ({ navData, siteConfig, show, closeModal }) => {
  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        onClose={closeModal}
        className="absolute z-20 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="relative rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
            <div className="flex justify-end">
              <button
                className="absolute top-0 right-0 box-content p-3 inline-flex items-center justify-center focus-white text-gray-400"
                onClick={closeModal}
              >
                <span className="sr-only">Close main menu</span>
                <XIcon className="h-8 w-8" aria-hidden="true" />
              </button>
            </div>
            <div className="pt-2">
              <div className="pt-5 px-5 space-y-1 divide-y divide-gray-100">
                {navData.map(item => (
                  <Fragment key={item.name}>
                    {item.children ? (
                      <MobileMenuParentItem item={item} />
                    ) : (
                      <Link
                        to={item.to}
                        state={item.state}
                        className={classNames(
                          mobileNavItemClass,
                          'font-medium'
                        )}
                      >
                        {item.name}
                      </Link>
                    )}
                  </Fragment>
                ))}
              </div>
              {siteConfig.twitter && (
                <a
                  href={siteConfig.twitter}
                  target="_blank"
                  className="mt-5 px-7 py-5 flex items-center space-x-3 bg-gray-50"
                >
                  <TwitterIcon className="w-6 h-6 text-blue-600" />
                  <span className="text-lg font-medium text-gray-700">
                    Follow us on twitter
                  </span>
                </a>
              )}
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}

const MobileMenuParentItem = ({ item }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <button
        className={classNames(
          mobileNavItemClass,
          'font-medium flex w-full text-left items-center space-x-2'
        )}
        onClick={() => setOpen(!open)}
      >
        <span>{item.name}</span>
        <ChevronDownIcon
          className={classNames('w-5 h-5 transform', open && 'rotate-180')}
        />
      </button>
      {open &&
        item.children.map(childItem => (
          <MobileMenuSubItem key={childItem.name} item={childItem} />
        ))}
    </>
  )
}

const MobileMenuSubItem = ({ item, depth = 0 }) => {
  let pl
  if (depth === 0) {
    pl = 'pl-5'
  } else if (depth === 1) {
    pl = 'pl-8'
  } else if (depth > 1) {
    pl = 'pl-11'
  }
  return (
    <>
      <Link
        key={item.name}
        to={item.to}
        state={item.state}
        className={classNames(mobileNavItemClass, pl)}
      >
        {item.name}
      </Link>
      <>
        {item.children &&
          item.children.map(childItem => (
            <MobileMenuSubItem
              key={childItem.name}
              item={childItem}
              depth={depth + 1}
            />
          ))}
      </>
    </>
  )
}

export default Header
