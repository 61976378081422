/* eslint react/jsx-no-target-blank: 0 */

import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import Logo from '../../../assets/images/svgs/logo.svg'
import TwitterIcon from '../../../assets/images/svgs/twitter-solid.svg'
import { getLinkProps } from '../../../utils/misc'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      allWp {
        nodes {
          acfOptionsSiteConfiguration {
            siteConfiguration {
              twitter
              footerText
              copyright
            }
          }
        }
      }
      menuFooterMain: wpMenu(locations: { eq: FOOTER_MAIN }) {
        menuItems {
          nodes {
            label
            path
          }
        }
      }
      menuFooterAbout: wpMenu(locations: { eq: FOOTER_ABOUT }) {
        name
        menuItems {
          nodes {
            label
            path
          }
        }
      }
    }
  `)
  const siteConfig =
    data.allWp.nodes[0].acfOptionsSiteConfiguration.siteConfiguration
  const menus = [
    {
      title: 'Main',
      items: data.menuFooterMain.menuItems.nodes.map(getLinkProps),
    },
    {
      title: 'About',
      items: data.menuFooterAbout.menuItems.nodes.map(getLinkProps),
    },
  ]
  const copyright = siteConfig.copyright.replace(
    '[current-year]',
    new Date().getFullYear()
  )
  return (
    <footer>
      <div className="pb-5 pt-8 md:pt-20 bg-light-blue-50 border-t border-gray-200">
        <div className="container">
          <div className="flex flex-col space-y-6 md:space-y-0 md:flex-row md:justify-between">
            <div className="max-w-xs space-y-6 sm:space-y-8">
              <a href="https://www.aspi.org.au/" target="_blank">
                <Logo className="text-gray-700 w-[86px] h-[86px]" />
              </a>
              {siteConfig.footerText && (
                <p className="text-gray-700">{siteConfig.footerText}</p>
              )}
              {siteConfig.twitter && (
                <div className="flex">
                  <a
                    href={siteConfig.twitter}
                    target="_blank"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <TwitterIcon className="w-6 h-6" />
                  </a>
                </div>
              )}
            </div>
            <div className="sm:flex space-x-0 sm:space-x-6 space-y-6 sm:space-y-0">
              {menus.map(menu => (
                <nav
                  key={menu.title}
                  className="flex-shrink-0 min-w-[180px] space-y-4"
                >
                  <p className="text-sm leading-5 font-semibold uppercase tracking-wider text-gray-400">
                    {menu.title}
                  </p>
                  {menu.items.map((item, i) => (
                    <div key={i}>
                      <Link
                        to={item.to}
                        state={item.state}
                        className="text-gray-700 hover:underline"
                      >
                        {item.name}
                      </Link>
                    </div>
                  ))}
                </nav>
              ))}
            </div>
          </div>
          <div className="py-5">
            <hr className="border-gray-200" />
          </div>
          <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:justify-between text-gray-400 text-center">
            <p>{copyright}</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
