const addNodeToTree = (node, tree, processNode) => {
  const processedNode = processNode(node)
  if (node.id in tree) {
    processedNode.children = tree[node.id]
    if (node.parentId === null) {
      tree.root.unshift(processedNode)
    } else {
      if (!(node.parentId in tree)) {
        tree[node.parentId] = []
      }
      tree[node.parentId].unshift(processedNode)
    }
  } else if (node.parentId === null) {
    tree.root.unshift(processedNode)
  } else {
    if (!(node.parentId in tree)) {
      tree[node.parentId] = []
    }
    tree[node.parentId].unshift(processedNode)
  }
}

const arrayToTree = (array, processNode) => {
  const tree = {
    root: [],
  }
  for (let i = array.length - 1; i >= 0; i--) {
    addNodeToTree(array[i], tree, processNode)
  }
  return tree.root
}

export default arrayToTree
